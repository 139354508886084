import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'src/base-component/Table/_Table';
import { Button, DatePicker, Input, Popover, Select, Space, TableProps } from 'antd';
import { CopyFilled, EditOutlined, FolderViewOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CustomerApi, DirectoriesApi, OrderApi, UserApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { IOM_Order } from 'main/services/OrderManager';
import { Formatter } from 'src/helpers';
import { OrderStatus, OrderStatusForm } from '../';
import { ICustomer, IStatus, IUser } from 'main/interfaces';
import _ from 'lodash';
import { OrderGridManager } from './OrderGridManager';

const { Option } = Select;

export const OrderGrid: React.FC = () => {
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState<IStatus[] | undefined>();
  const [customers, setCustomers] = useState<ICustomer[]>();
  const [users, setUsers] = useState<IUser[]>();

  const customerOptions = customers?.map((customer) => ({
    value: customer.id,
    label: (customer?.info?.surname ?? '') + ' ' + (customer?.info?.name ?? '')
  }));

  const getCustomers = useCallback((name?: string): void => {
    CustomerApi.getAll({ name }).then((res) => {
      setCustomers(res?.data?.rows);
    });
  }, []);

  const handleSearchCustomer = _.debounce((value: string) => {
    getCustomers(value);
  }, 1000);

  const filters = [
    {
      name: 'id',
      title: 'ID',
      field: <Input />
    },
    {
      name: 'customer_payment',
      title: 'Платник',
      field: <Input />
    },
    {
      name: 'id_status',
      title: 'Статус',
      field: (
        <Select placeholder="Оберіть варіант із списку" mode="multiple">
          {statuses?.map((status) => (
            <Option value={status.id} key={status.id}>
              <OrderStatus id={status.id} name={status?.info?.[0] ? status.info[0]?.value : ''} />
            </Option>
          ))}
        </Select>
      )
    },

    {
      name: 'id_customer',
      title: 'Клієнт',
      field: (
        <Select placeholder="Оберіть варіант із списку"
                showSearch
                options={customerOptions}
                onSearch={handleSearchCustomer}
                filterOption={(value, option) => option?.label?.includes(value) ?? false}
        />
      )
    },
    {
      name: 'dateFrom',
      title: 'Дата від',
      field: <DatePicker style={{ width: '100%' }} />
    },
    {
      name: 'dateTo',
      title: 'Дата до',
      field: <DatePicker style={{ width: '100%' }} />
    },
    {
      name: 'id_manager',
      title: 'Менеджер',
      field: (
        <Select placeholder="Оберіть варіант із списку">
          {users?.map((user) => (
            <Option value={user.id} key={user.id}>
              {user?.info?.[0] ? user?.info[0]?.name : ''}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const handleOpen = (id: number): () => void => {
    return () => navigate(ROUTES.ORDERS + '/views/' + id);
  };

  const handleEdit = (id: number): () => void => {
    return () => navigate(ROUTES.ORDERS + '/edit/' + id);
  };

  const handleCreate = (id: number): () => void => {
    return () => {
      OrderApi.copy(String(id)).then((res) => {
        if (res?.data?.id) navigate(ROUTES.ORDERS + '/edit/' + res?.data?.id);
      });
    };
  };

  const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<IOM_Order>['columns'] => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Сума',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => Formatter.toMoney(record.total ?? 0, true) + ' грн',
      width: '150px'
    },
    {
      title: 'Статус',
      dataIndex: 'id_status',
      key: 'id_status',
      sorter: true,
      render: (_, record) => (
        <Popover content={<OrderStatusForm order_id={record.id} allowed_statuses={record.allowedStatuses ?? []} onChange={handleGetData} />} trigger="click">
          <Button type={'link'}>
            <OrderStatus id={record.id_status} name={record.status_name ?? ''} />
          </Button>
        </Popover>
      )
    },
    {
      title: 'Передзамовлення',
      dataIndex: 'is_preorder',
      key: 'is_preorder',
      sorter: true,
      render: (_, record) => record.is_preorder ? 'Передзамовлення' : '-'
    },
    {
      title: 'Партнерське',
      dataIndex: 'is_partner',
      key: 'is_partner',
      sorter: true,
      render: (_, record) => record.is_partner ? 'Партнерське' : '-'
    },
    {
      title: 'Замовник',
      dataIndex: 'payment_client',
      key: 'payment_client'
    },
    {
      title: 'Платник',
      dataIndex: 'payment_client',
      key: 'payment_client',
      render: (_, record) => record?.payment_company ?? record?.payment_client
    },
    {
      title: 'Менеджер',
      dataIndex: 'id_user',
      key: 'id_user',
      render: (_, record) => <OrderGridManager order_id={record.id} id_manager={record.id_manager} users={users ?? []} onChange={handleGetData}/>
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_, record) => Formatter.toDate(record.createdAt)
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleEdit(record.id)}>
            <EditOutlined />
          </Button>
          <Button onClick={handleOpen(record.id)}>
            <FolderViewOutlined />
          </Button>
          <Button onClick={handleCreate(record.id)}>
            <CopyFilled />
          </Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    if (!statuses) {
      DirectoriesApi.getStatuses().then((res) => {
        setStatuses(res?.data);
      });
    }
  }, [statuses]);

  useEffect(() => {
    if (!customers) {
      getCustomers();
    }
  }, [customers, getCustomers]);

  useEffect(() => {
    if (!users) {
      UserApi.getAll({}).then((res) => {
        setUsers(res?.data?.rows);
      });
    }
  }, [users]);

  return <Table getColumns={getColumns} getData={OrderApi.getAll.bind(OrderApi)} filtersTypes={filters} />;
};
